// eslint-disable-next-line import/no-anonymous-default-export
export default {
  brand: {
    gray: "#424242",
    black: "#222222",
    primary: "#0BB5A8",
    secondary: "#FFB300",
    light: "#70F2AB",
    dark: "#0A9F94",
    alpha: "rgba(11, 181, 168, 0.9)",
  },
  facebook: "#3B5998",
  instagram: "#E1306C",
  linkedin: "#0077B5",
}
