import "react-responsive-carousel/lib/styles/carousel.min.css"
import "@fontsource/lexend/400.css"
import "@fontsource/lexend/500.css"
import "@fontsource/lexend/700.css"

import {
  GlobalStyle,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  TextProps,
  UnorderedList,
} from "@chakra-ui/react"
import Header from "@components/headings/Header"
import NormalHeading from "@components/headings/NormalHeading"
import Paragraph from "@components/headings/Paragraph"
import ChakraRTLProvider from "@components/layout/ChakraRTLProvider"
import { MDXProvider } from "@mdx-js/react"
import Fonts from "@styles/fonts"
import * as fbPixel from "@utils/fbpixel"
import * as gtag from "@utils/gtag"
import { GTM_TRACKING_ID } from "@utils/gtm"
import { isDevEnvironment } from "@utils/utils"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import Script from "next/script"
import { NextIntlClientProvider } from "next-intl"
import { DefaultSeo } from "next-seo"
import { useEffect } from "react"

const components = {
  a: (props: LinkProps) => (
    <Link
      color={"brand.primary"}
      textDecoration={"underline"}
      {...props}
    />
  ),
  h1: (props: HeadingProps) => (
    <NormalHeading
      as={"h1"}
      {...props}
    />
  ),
  h2: (props: HeadingProps) => (
    <Header
      as={"h2"}
      fontSize={"2xl"}
      color={"brand.primary"}
      {...props}
    />
  ),
  h3: (props: HeadingProps) => (
    <Header
      as={"h3"}
      fontSize={"xl"}
      color={"brand.primary"}
      {...props}
    />
  ),
  p: (props: TextProps) => <Paragraph {...props} />,
  ul: (props: ListProps) => (
    <UnorderedList
      ms={8}
      {...props}
    />
  ),
  ol: (props: ListProps) => (
    <OrderedList
      ms={8}
      {...props}
    />
  ),
  li: (props: ListItemProps) => <ListItem {...props} />,
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.gtagPageView(url)
      fbPixel.fbPageView()
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    router.events.on("hashChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      router.events.off("hashChangeComplete", handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const dir = router.locale === "ar" ? "rtl" : "ltr"
    const html = document.querySelector("html")
    if (html) {
      html.setAttribute("dir", dir)
    }
  }, [router.locale])

  return (
    <NextIntlClientProvider
      messages={pageProps.messages}
      locale={router.locale}
      timeZone={"Asia/Dubai"}
    >
      <ChakraRTLProvider>
        <GlobalStyle />
        <Fonts />
        <DefaultSeo
          defaultTitle={
            "Digital eMenu - Scan, Order, Pay – Effortless Dining at Your Fingertips"
          }
          titleTemplate={"%s"}
          description={
            "Digital eMenu - Experience a seamless digital dining solution with eMenu. From scanning the QR code to ordering and paying, eMenu offers a complete, contactless experience for modern dining. Perfect for restaurants, cafes, and hotels looking to enhance customer convenience and operational efficiency."
          }
          openGraph={{
            type: "website",
            url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
            title:
              "Digital eMenu | Scan, Order, Pay – Effortless Dining at Your Fingertips",
            description:
              "Digital eMenu - Experience a seamless digital dining solution with eMenu. From scanning the QR code to ordering and paying, eMenu offers a complete, contactless experience for modern dining. Perfect for restaurants, cafes, and hotels looking to enhance customer convenience and operational efficiency.",
            images: [
              {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}/images/og-image.jpg`,
                width: 800,
                height: 600,
                alt: "Digital eMenu | Scan, Order, Pay – Effortless Dining at Your Fingertips",
              },
            ],
          }}
        />
        {!isDevEnvironment && (
          <>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
              strategy={"afterInteractive"}
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <Script
              id={"gtag-init"}
              strategy={"afterInteractive"}
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${gtag.GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
              `,
              }}
            />
            {/* Google Tag Manager */}
            <Script
              id={"google-tag-manager"}
              strategy={"afterInteractive"}
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_TRACKING_ID}'
                  );
                `,
              }}
            />
            {/* Facebook Pixel */}
            <Script
              id={"fb-pixel"}
              strategy={"afterInteractive"}
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${fbPixel.FB_PIXEL_ID});
                  fbq('track', 'PageView');
                `,
              }}
            />
          </>
        )}
        <MDXProvider components={components}>
          <Component {...pageProps} />
        </MDXProvider>
      </ChakraRTLProvider>
    </NextIntlClientProvider>
  )
}

export default MyApp
