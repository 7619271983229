import { DrawbackItem, EmenuSamples } from "@definitions/types"

type TypeName = DrawbackItem[] | EmenuSamples[]

type ObjectType<T> = T extends DrawbackItem[]
  ? DrawbackItem[]
  : T extends EmenuSamples[]
    ? EmenuSamples[]
    : never

export function partitionArray<T extends TypeName>(
  array: T,
  size: number
): ObjectType<T>[] {
  return array
    .map((e, i) => (i % size === 0 ? array.slice(i, i + size) : null))
    .filter((e) => e) as ObjectType<T>[]
}

export const isDevEnvironment =
  process && process.env.NODE_ENV === "development"

export const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase())
    })
    .join(" ")
}
