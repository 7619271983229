// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: () => ({
    body: {
      color: "brand.primary",
      bg: "white",
      lineHeight: "base",
      scrollbarColor: `#0BB5A8 transparent`,
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        width: "6px",
        backgroundColor: "#C5E1F2",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0BB5A8",
        borderRadius: "6px",
      },
    },
  }),
}
