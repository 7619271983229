import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Wild Spirit Alt';
        src: url('/fonts/wild_spirit_alt/wild_spirit_alt.woff2') format('woff2'),
             url('/fonts/wild_spirit_alt/wild_spirit_alt.woff') format('woff');
             url('/fonts/wild_spirit_alt/wild_spirit_alt.otf') format('opentype'),
        font-weight: 400;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Arslan Wessam';
        src: url('/fonts/arslan-wessam/AArslanWessamA-AArslanWessamA.woff2') format('woff2'),
             url('/fonts/arslan-wessam/AArslanWessamA-AArslanWessamA.woff') format('woff'),
             url('/fonts/arslan-wessam/AArslanWessamA-AArslanWessamA.eot?#iefix') format('embedded-opentype'),
             url('/fonts/arslan-wessam/AArslanWessamA-AArslanWessamA.ttf') format('truetype');
        font-weight: 400;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Dubai';
        src: url('/fonts/dubai/DubaiW23-Regular.woff2') format('woff2'),
             url('/fonts/dubai/DubaiW23-Regular.woff') format('woff'),
             url('/fonts/dubai/DubaiW23-Regular.eot?#iefix') format('embedded-opentype'),
             url('/fonts/dubai/DubaiW23-Regular.ttf') format('ttf');
        font-weight: 400;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Dubai';
        src: url('/fonts/dubai/DubaiW23-Medium.woff2') format('woff2'),
             url('/fonts/dubai/DubaiW23-Medium.woff') format('woff'),
             url('/fonts/dubai/DubaiW23-Medium.eot?#iefix') format('embedded-opentype'),
             url('/fonts/dubai/DubaiW23-Medium.ttf') format('ttf');
        font-weight: 500;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Dubai';
        src: url('/fonts/dubai/DubaiW23-Bold.woff2') format('woff2'),
             url('/fonts/dubai/DubaiW23-Bold.woff') format('woff'),
             url('/fonts/dubai/DubaiW23-Bold.eot?#iefix') format('embedded-opentype'),
             url('/fonts/dubai/DubaiW23-Bold.ttf') format('ttf');
        font-weight: 700;
        font-display: swap;
      }
    `}
  />
)

export default Fonts
