import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import colors from "@definitions/chakra/theme/foundations/colors"
import config from "@definitions/chakra/theme/foundations/config"
import fonts_ar from "@definitions/chakra/theme/foundations/fonts_ar"
import fonts_en from "@definitions/chakra/theme/foundations/fonts_en"
import styles from "@definitions/chakra/theme/foundations/styles"
import { useRouter } from "next/router"
import { ReactNode } from "react"

const ChakraRTLProvider = ({ children }: { children: ReactNode }) => {
  const { locale } = useRouter()
  const direction = locale === "ar" ? "rtl" : "ltr"

  const fonts = locale === "ar" ? fonts_ar : fonts_en

  const overrides = {
    config,
    fonts,
    colors,
    styles,
    direction,
  }

  const theme = extendTheme(overrides)

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}

export default ChakraRTLProvider
