import { gtagEventProps } from "@definitions/types"
import { isDevEnvironment } from "@utils/utils"

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageView = (url: string) => {
  !isDevEnvironment &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({
  action,
  category,
  label,
  value,
}: gtagEventProps) => {
  !isDevEnvironment &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    })
}
