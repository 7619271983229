import { Heading, HeadingProps } from "@chakra-ui/react"

export default function NormalHeading(props: HeadingProps) {
  return (
    <Heading
      as={"h2"}
      px={{ base: 0, md: 24, xl: 36 }}
      fontWeight={"700"}
      textAlign={"center"}
      mt={{ base: 10, md: 100 }}
      fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
      color={"brand.primary"}
      {...props}
    >
      {props.children}
    </Heading>
  )
}
